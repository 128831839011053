import React, { useState, useMemo, useContext, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import _ from "lodash";
import { Upload, Alert, Dropdown, Menu } from 'antd';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCard } from 'Components/Gui/NwCard/NwCard';
import { NwButton } from 'Components/Gui/NwButton';
import NwUpload from "Components/Gui/NwUpload";
import FlexContainer from 'Components/Gui/FlexContainer';
import Switch from 'Components/Gui/CustomSwitch';
// import RatingSelector from "Components/Gui/NWForm/RatingSelector";
import FilterTagSelector from 'Components/FilterTagSelector/FilterTagSelector';
import NWSlider from "Components/Gui/NWSlider";
import AddWebVideoDrawer from 'Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsMedia/AddWebVideoDrawer';
import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";
import MediaDrawer from "Components/MediaDrawer/MediaDrawer";
import NwIcon from 'Components/Gui/NwIcon';
import { showLog } from "Libs/NwMoment";
import { getThumbnailWidth } from "Libs/NwUtils";
import { useStateValue } from 'Contexts/StateProvider';
import { ListContext } from 'Contexts/ListContext';
import { UserContext, useUserPermission } from 'Contexts/UserContext';
import { forceReloadContact } from "Hooks/Contact/UseContact"; //useContact
import { onContactMediaUpdate } from 'Hooks/Contact/UseContactMedia';
import ModelMediaItem from 'Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsMedia/ModelMediaItem';
import NwLink from 'Components/Gui/NwLink';

const Wrapper = styled.div`
    .ant-card-actions > li {
        margin: 8px 0;
    }
    .ant-card-body {
        .ant-card-body-content {
            padding: 0.5rem 1rem 1.5rem 1rem;
        }
    }
`;

const SliderContainer = styled.div`
    margin: 1rem 2rem;

    .slick-prev {
      left: -30px;
    }

    .slick-next {
      right: -30px;
    }
    
    .slick-prev:before, .slick-next:before  {
        content: '' !important;
    }


    .slick-slide {
        margin-right:  2px;
    }

    .slick-dots {
        bottom: -2rem;

        li {
            margin: 0;
            button {
                :before {
                    font-size: .65rem;
                }
            }
        }
    }
`;

const ShowMoreMediaDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 240px;
    height: 180px;
    background-color: #000;
    color: #fff;

    p {
        max-width: 180px;
    }
`;

const StyledAlert = styled(Alert)`
    margin-bottom: 8px;
`;

const LockContainer = styled(FlexContainer)`
    min-height: 320px;
    padding: 16px 0;
    svg {
        font-size: ${props => props.$previewMode ? '80px' : '120px'};
    }
`;

const thumbheight = 180;

const ModelDetailsMedia = ({ currentContact, previewMode, scoutingModel, meetingMediaList, noDots }) => {
    const history = useHistory();
    const [{ areaContext }] = useStateValue();
    //const { data: currentContact } = useContact(contactID);
    const [uploadingPictures, setUploadingPictures] = useState([]);
    const [invalidUploadingPictures, setInvalidUploadingPictures] = useState([]);
    const [uploadingVideos, setUploadingVideos] = useState([]);
    const [invalidUploadingVideos, setInvalidUploadingVideos] = useState([]);
    const { GetUserName } = useContext(ListContext);
    const { currentUser } = useContext(UserContext);
    const [viewPictureType, setViewPictureType] = useState(scoutingModel ? 'meeting' : 'all');
    const viewModelMedia = useUserPermission('ViewModelMedia', currentContact && currentContact.Users.map(user => user.UserID));
    const [hasEncodingVideo, setHasEncodingVideo] = useState(false);

    const [onlyMine, setOnlyMine] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    // const [selectedRating, setSelectedRating] = useState();
    const [filterMediaType, setFilterMediaType] = useState('all');
    const [selectedMedia, setSelectedMedia] = useState();
    const [linkWebVideo, setLinkWebVideo] = useState(false);
    const [showMoreMessage, setShowMoreMessage] = useState("");
    const timerRef = useRef(null);

    useEffect(() => {
        if (hasEncodingVideo) {
            timerRef.current = setInterval(() => {
                forceReloadContact(currentContact.ID);
            }, 8000);
        } else {
            clearInterval(timerRef.current);
        }
    }, [hasEncodingVideo]);

    useEffect(() => {
        return () => clearInterval(timerRef.current);
    }, []);

    const handleLinkWebVideo = () => {
        setLinkWebVideo(true);
    }

    const contactMediaList = useMemo(() => {
        let filteredMediaList = [];
        if (currentContact) {
            filteredMediaList = [...currentContact.Images.map(image => ({ ...image, type: 'image' })),
            ...currentContact.Videos.map(video => ({ ...video, type: 'video' }))];
            filteredMediaList = filteredMediaList.filter(video => {
                if (video.Visibility.Areas.length === 0 || video.Visibility.Areas.find(area => area === areaContext.area)) {
                    return true;
                }
                return false;
            })
        }
        return filteredMediaList;
    }, [currentContact]);

    const goToMediaPage = () => {
        if (areaContext.area === 'booking') {
            history.push(`/models/${currentContact.ID}/media`);
        } else {
            history.push(`/${areaContext.area}-models/${currentContact.ID}/media`);
        }
    };

    const afterUploadingPictures = () => {
        setUploadingPictures([]);
    };

    const afterUploadingVideos = () => {
        setUploadingVideos([]);
    };

    const handleTagsChangeFilter = (name, tags) => {
        setSelectedTags(tags);
    };

    // const mapPictureTags = tags => {
    //     return tags.map(function (tag) {
    //         return {
    //             value: tag,
    //             title: tag
    //         };
    //     });
    // };

    const getTagsFromModelImages = () => {
        let tagsArray = [];
        contactMediaList.map(image => image.Tags.map(tag => {
            if (!tagsArray.includes(tag)) {
                tagsArray.push(tag)
            }
            return null;
        }));
        return tagsArray;
    };

    const onClickMedia = (media) => {
        if (!previewMode) {
            setSelectedMedia(media);
        }
    };

    const onCloseDrawer = () => {
        setSelectedMedia();
    }

    const convertObjects = mediaList => {
        setHasEncodingVideo(false);
        return mediaList.map(function (media) {
            if (media.Standard && media.Thumbnail) {
                return {
                    id: media.ID,
                    src: media.Standard.Url,
                    thumbnail: media.Thumbnail.Url,
                    thumbnailWidth: getThumbnailWidth(media, thumbheight),
                    thumbnailHeight: thumbheight,
                    tags: media.Tags,
                    caption: `${GetUserName(media.UserCreated)}, ${showLog(media.DateCreated)}`,
                    ...media
                };
            } else {
                if (!media.Standard && !media.Thumbnail) {
                    setHasEncodingVideo(true);
                    return {
                        id: media.ID,
                        src: null,
                        thumbnail: null,
                        //thumbnailWidth: GetThumbnailWidth(media, thumbheight),
                        thumbnailWidth: 240,
                        thumbnailHeight: thumbheight,
                        tags: [],
                        caption: "ENCODING VIDEO",
                        ...media
                    };
                } else {
                    return {
                        id: media.ID,
                        src: null,
                        thumbnail: null,
                        thumbnailWidth: 240,
                        thumbnailHeight: thumbheight,
                        tags: [],
                        caption: "NO THUMBNAIL",
                        ...media
                    };
                }
            }
        });
    };

    const getFilteredMediaList = mediaList => {
        if (!selectedTags.length && /*!selectedRating &&*/ !onlyMine) {
            return mediaList;
        } else {
            let filteredMediaList = [];
            let ratingType = null,
                ratingValue = null;

            // if (selectedRating) {
            //     const rating = selectedRating.split("-");
            //     if (_.isArray(rating) && rating.length === 2) {
            //         ratingType = rating[0];
            //         ratingValue = parseInt(rating[1]);
            //     }
            // }

            mediaList.forEach(media => {
                const mediatags = media.Tags;
                if ((_.difference(selectedTags, mediatags).length === 0) &&
                    (!onlyMine || media.UserCreated === currentUser.UserID)) {
                    if (ratingType && ratingValue) {
                        media.Rating[ratingType] >= ratingValue && filteredMediaList.push(media);
                    }
                    else {
                        filteredMediaList.push(media);
                    }
                }
            });

            return filteredMediaList;
        }
    };

    const mediaListToRender = useMemo(() => {
        let mediaList = viewPictureType === 'meeting' ? [...meetingMediaList] : [...contactMediaList];
        let filteredMediaList = getFilteredMediaList(mediaList);
        if (filterMediaType === 'video') {
            filteredMediaList = filteredMediaList.filter(media => media.type === 'video');
        }
        if (filterMediaType === 'image') {
            filteredMediaList = filteredMediaList.filter(media => media.type === 'image');
        }
        filteredMediaList.sort((media1, media2) => {
            return new Date(media2.DateCreated) > new Date(media1.DateCreated) ? 1 : -1
        });
        if (filteredMediaList.length > 100) {
            setShowMoreMessage(`${filteredMediaList.length - 100} more media for this model`);
            filteredMediaList = filteredMediaList.slice(0, 100);
        }
        // sortedMediaList.sort((item1, item2) => {
        //     return new Date(item2.DateCreated) > new Date(item1.DateCreated) ? 1 : -1;
        // });
        return convertObjects(filteredMediaList);
        //return filteredMediaList;

    }, [selectedTags, onlyMine, currentContact, filterMediaType, viewPictureType]);

    const selectMediaBefore = () => {
        if (selectedMedia && mediaListToRender && mediaListToRender.length > 0) {
            const findIndex = mediaListToRender.findIndex(media => media.ID === selectedMedia.ID && media.type === selectedMedia.type);
            if (findIndex > 0) {
                setSelectedMedia(mediaListToRender[findIndex - 1]);
            }
        }
    };

    const selectMediaAfter = () => {
        if (selectedMedia && mediaListToRender && mediaListToRender.length > 0) {
            const findIndex = mediaListToRender.findIndex(media => media.ID === selectedMedia.ID && media.type === selectedMedia.type);
            if (findIndex > -1 && findIndex < mediaListToRender.length - 1) {
                setSelectedMedia(mediaListToRender[findIndex + 1]);
            }
        }
    }

    // const selectLastCreatedPicture = useCallback(() => {
    //     if (selectedMedia && mediaListToRender && mediaListToRender.length > 0) {
    //         setSelectedMedia(mediaListToRender[0]);
    //     }
    // }, [mediaListToRender, selectedMedia]);

    // const handleMediaUpdate = () => {
    //     if (selectedMedia.type === 'image') {
    //         onContactMediaUpdate(currentContact.ID, 2, null);
    //     } else {
    //         onContactMediaUpdate(currentContact.ID, 3, null);
    //     }
    // };

    const renderFilters = () => {
        return (scoutingModel ?
            <FlexContainer>
                <RadioButtonGroup
                    value={viewPictureType}
                    onChange={setViewPictureType}
                    size='small'
                    options={[
                        { label: 'Meeting Media', value: 'meeting' },
                        { label: 'All', value: 'all' }
                    ]} />
            </FlexContainer>
            : previewMode ? null
                : <FlexContainer fullWidth justifyContent='space-between'>
                    <div>
                        <FlexContainer mg='0 0 8px'>
                            <RadioButtonGroup
                                value={filterMediaType}
                                onChange={setFilterMediaType}
                                size='small'
                                defaultValue="all"
                                options={[
                                    { label: 'Pictures', value: 'image' },
                                    { label: 'Videos', value: 'video' },
                                    { label: 'All', value: 'all' }
                                ]} />
                        </FlexContainer>
                        <Switch text='only mine' checked={onlyMine} onChange={setOnlyMine} />
                    </div>
                    <div>
                        <FlexContainer mg='0 0 8px'>
                            <FilterTagSelector selectedOptions={selectedTags} tags={getTagsFromModelImages()} onChange={handleTagsChangeFilter} />
                        </FlexContainer>
                        {/* <RatingSelector onChange={(rate) => setSelectedRating(rate)} value={selectedRating} /> */}
                    </div>
                </FlexContainer>
        )
    };

    const getActiveFilters = () => {
        let filters = [];
        if (viewPictureType === 'meeting') {
            filters.push('Meeting Media');
        }
        if (filterMediaType !== 'all') {
            filters.push(filterMediaType === 'image' ? 'Pictures' : 'Videos');
        }
        if (onlyMine) {
            filters.push('Mine only');
        }
        if (selectedTags.length > 0) {
            filters.push(`Tags: ${selectedTags.join(', ')}`);
        }
        // if (selectedRating) {
        //     const rating = selectedRating.split("-");
        //     if (_.isArray(rating) && rating.length === 2) {
        //         const ratingValue = parseInt(rating[1]);
        //         filters.push(`Rating > ${ratingValue}`);
        //     }
        // }
        return filters;
    };

    const uploadMenuItems = [
        {
            key: "Pictures",
            label: 
                <NwUpload
                    maxSize={50}
                    showList={false}
                    types={["image"]}
                    onUploadStart={(uploadInfo) => {
                        if (uploadInfo.event) {
                            setUploadingPictures(uploadInfo.fileList)
                        }
                    }}
                    onShowInvalidFiles={invalidFiles => setInvalidUploadingPictures(invalidFiles)}
                    onUploadEnd={() => {
                        onContactMediaUpdate(currentContact.ID, afterUploadingPictures);
                    }}
                    endpoint={`documents/uploadsingle/2?contactID=${currentContact.ID}`}
                >
                    <NwIcon icon={solid("cloud-upload")} /> Upload Pictures
                </NwUpload>
            
        },
        {
            key: "Videos",
            label: 
                <NwUpload
                    showList={true}
                    maxSize={50}
                    types={["video"]}
                    endpoint={`documents/uploadsingle/3?contactID=${currentContact.ID}`}
                    onUploadStart={(uploadInfo) => {
                        if (uploadInfo.event) {
                            setUploadingVideos(uploadInfo.fileList)
                        }}
                    }
                    onShowInvalidFiles={invalidFiles => setInvalidUploadingVideos(invalidFiles)}
                    onUploadEnd={() => {
                        onContactMediaUpdate(currentContact.ID, afterUploadingVideos);
                    }}
                >
                    <NwIcon icon={solid("cloud-upload")} /> Upload Videos
                </NwUpload>
            
        },
        {
            key: "WebVideo",
            onClick: handleLinkWebVideo,
            label: 
                <span>
                    <NwIcon icon={solid("cloud-upload")} /> Link Web Video
                </span>
            
        }
    ]

    const ShowMoreMedia = ({}) => {
        return (
            <ShowMoreMediaDiv>
                <p>{showMoreMessage}</p>
                <br/>
                <p><NwLink onClick={goToMediaPage}>Go to model media page</NwLink></p>
            </ShowMoreMediaDiv>
        )
    }

    return (
        <Wrapper>
            <NwCard
                minheight={300}
                filtersContent={viewModelMedia && renderFilters()}
                activeFilters={viewModelMedia && getActiveFilters()}
                title={`Media ${currentContact ? `[${currentContact.Images.length} pictures, ${currentContact.Videos.length} videos]` : ''}`}
                titleAction={viewModelMedia &&
                    <NwButton
                        minimal
                        icon={light('expand-wide')}
                        solid
                        size="small"
                        onClick={goToMediaPage} />
                }
                extra={
                    (previewMode || !viewModelMedia) ? <div />
                    : 
                    <Dropdown menu={{ items: uploadMenuItems }}>
                        <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <NwButton ghost label={'Upload'} icon={solid("cloud-upload")} />
                        </div>
                    </Dropdown>
                }
                padding="reduced">
                {viewModelMedia ?
                    <>
                        {(uploadingPictures.length > 0 || uploadingVideos.length > 0) ?
                            <>
                                {invalidUploadingPictures.map(file => {
                                    return (
                                        <StyledAlert key={file.uid} message={`${file.name} ${file.reason}`} type="error" showIcon />
                                    )
                                })}
                                {invalidUploadingVideos.map(file => {
                                    return (
                                        <StyledAlert key={file.uid} message={`${file.name} ${file.reason}`} type="error" showIcon />
                                    )
                                })}
                                <Upload
                                    name="files"
                                    showUploadList={{
                                        showRemoveIcon: false
                                    }}
                                    listType="picture"
                                    multiple={true}
                                    fileList={uploadingPictures.length ? uploadingPictures : uploadingVideos}
                                />
                            </>
                            :
                            <SliderContainer>
                                <NWSlider
                                    slidesToShow={previewMode ? 1 : mediaListToRender.length < 4 ? mediaListToRender.length : 4}
                                    slidesToScroll={previewMode ? 1 : mediaListToRender.length < 4 ? mediaListToRender.length : 4}
                                    swipeToSlide
                                    dots={!noDots}
                                    responsive={[
                                        {
                                            breakpoint: 1300,
                                            settings: {
                                                slidesToShow: previewMode ? 1 : mediaListToRender.length < 3 ? mediaListToRender.length : 3,
                                                slidesToScroll: previewMode ? 1 : mediaListToRender.length < 3 ? mediaListToRender.length : 3,
                                            }
                                        },
                                        {
                                            breakpoint: 800,
                                            settings: {
                                                slidesToShow: previewMode ? 1 : mediaListToRender.length < 2 ? mediaListToRender.length : 2,
                                                slidesToScroll: previewMode ? 1 : mediaListToRender.length < 2 ? mediaListToRender.length : 2,
                                            }
                                        },
                                        {
                                            breakpoint: 600,
                                            settings: {
                                                slidesToShow: 1,
                                                slidesToScroll: 1
                                            }
                                        }
                                    ]}
                                >
                                    {mediaListToRender.map((media, index) => {
                                        if (media.type === 'video') {
                                            return (
                                                <ModelMediaItem
                                                    key={`video-${media.ID}`}
                                                    media={media}
                                                    type="video"
                                                    onMediaClicked={onClickMedia}
                                                />
                                            )
                                        } else {
                                            return (
                                                <ModelMediaItem
                                                    media={media}
                                                    type="photo"
                                                    key={`picture-${media.ID}`}
                                                    onMediaClicked={onClickMedia}
                                                />
                                            )
                                        }
                                    })}
                                    {showMoreMessage &&
                                        <ShowMoreMedia />
                                    }
                                </NWSlider>
                            </SliderContainer>
                        }
                    </>
                    :
                    <LockContainer $previewMode={previewMode} justifyContent='center' alignItems='center'>
                        <NwIcon icon={light('lock-alt')} />
                    </LockContainer>
                }
            </NwCard>
            {selectedMedia &&
                <MediaDrawer
                    mediaID={selectedMedia.ID}
                    contactID={currentContact.ID}
                    onCloseDrawer={onCloseDrawer}
                    onSelectMediaBefore={selectMediaBefore}
                    onSelectMediaAfter={selectMediaAfter}
                    afterNewMediaCreation={onCloseDrawer}
                    //can't open the new media created, so I just close the drawer
                    //afterNewMediaCreation={selectLastCreatedPicture}
                    
                />
            }
            {linkWebVideo &&
                <AddWebVideoDrawer
                    contactID={currentContact.ID}
                    onClose={() => setLinkWebVideo(false)}
                />
            }
        </Wrapper>
    );
};

export default ModelDetailsMedia;